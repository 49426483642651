import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import ArticleTemplateComponent from "../components/templates/article";

const embedded = false;
const ArticleTemplate = function ({ data }) {
  return (
    <Layout embedded={embedded}>
      <ArticleTemplateComponent data={data} embedded={embedded} />
    </Layout>
  );
}

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($Title: String!) {
    strapiArticle(Title: { eq: $Title }) {
      Title
      Body
      Seotitle
      Seodescription
      Author {
        username
      }
    }
  }
`