import React from "react"
import showdown from "showdown";
import LinkMaintainEmbedded from "../../components/LinkMaintainEmbedded";
import SEO from "../../components/seo";

const ArticleTemplateComponent = function ({ data, embedded, webViewVersion }) {
  const article = data.strapiArticle;
  const converter = new showdown.Converter();
  const body = converter.makeHtml(article.Body);
  return (
    <div>
      <SEO title={article.Seotitle} description={article.Seodescription} />
      <p style={{fontSize: 'smaller'}}>
        <LinkMaintainEmbedded to={`/articles/`} embedded={embedded} webViewVersion={webViewVersion}>
          Camping
        </LinkMaintainEmbedded>
      </p>
      <h1>{article.Title}</h1>
      {/*this should be ok, but I'm going to be publishing and entirely static website*/}
      <p dangerouslySetInnerHTML={{__html: body}} />
      {/*<ul>*/}
      {/*  {article.images.map(image => (*/}
      {/*    <li key={image.id}>*/}
      {/*      placeholder*/}
      {/*    </li>*/}
      {/*  ))}*/}
      {/*</ul>*/}
    </div>
  );
}

export default ArticleTemplateComponent